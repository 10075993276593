<template>
  <div class="robot-address">
    <div class="cell">
      <el-tabs @tab-click="handleQH" v-model="activeName" type="border-card">
        <el-tab-pane name="management" label="管理">
          <el-form size="mini" inline ref="form2" :model="form" label-width="100px" style="margin: 0 0 10px">
            <el-form-item label="客服问题：">
              <el-input style="width: 160px" clearable v-model="form2.content"></el-input>
            </el-form-item>
            <el-form-item label="问题类型：">
              <el-select clearable style="width: 160px" v-model="form2.kf_type" placeholder="请选择" @change="handleClick">
                <el-option v-for="(item, index) in prodType" :key="index" :label="item.name"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="问题分类：">
              <el-select clearable style="width: 160px" v-model="form2.kf_category" placeholder="请选择"
                @change="handleClick">
                <el-option v-for="(item, index) in prodClass" :key="index" :label="item.name"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="类目：">
              <el-select clearable style="width: 160px" v-model="form2.gc1_id" placeholder="请选择" @change="handleClick">
                <el-option v-for="(item, index) in category" :key="index" :label="item.name"
                  :value="item.category_id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleClick">查询</el-button>
            </el-form-item>
          </el-form>

          <div style="
              padding: 16px;
              background: #f3f5f8;
              color: #09c;
              font-size: 13px;
            ">
            <span style="cursor: pointer" @click="btnType = btnType ? false : true">
              操作提示 <i class="el-icon-caret-top" v-if="btnType"></i>
              <i class="el-icon-caret-bottom" v-else></i>
            </span>
          </div>
          <div style="font-size: 12px; padding: 10px" v-if="btnType">
            1、买家机器人添加后，系统会随机给卖家分配下单的买家机器人和提问题的买家客服。
            <br />
            2、下单规则根据订单生成机制出单。
          </div>
          <el-table :data="tableData.list" style="width: 100%">
            <el-table-column prop="content" label="问题（英文）" align="center"></el-table-column>
            <el-table-column align="center" prop="kf_type_text" label="问题类型">
            </el-table-column>
            <el-table-column align="center" prop="kf_category_text" label="问题分类">
            </el-table-column>

            <el-table-column align="center" prop="status" label="状态">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.status == 1">启用</el-tag>
                <el-tag v-else type="info">停用</el-tag>
              </template>
            </el-table-column>
            <el-table-column width="200" align="center" prop="address" label="操作">
              <template slot-scope="scope">
                <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
                <el-button v-if="scope.row.status == 0" size="mini" type="success"
                  @click="handleONandOFF(scope.row, 1)">启用</el-button>
                <el-button v-else size="mini" type="danger" @click="handleONandOFF(scope.row, 0)">停用</el-button>
              </template>
            </el-table-column>
          </el-table>

          <el-pagination small style="padding: 20px 10px" background :page-size="page_size" :current-page="page"
            @current-change="handlePage" layout="prev, pager, next" :total="tableData.total">
          </el-pagination>
        </el-tab-pane>
        <el-tab-pane name="add" :label="title">
          <el-form size="small " ref="form" :rules="rules" :model="form" label-width="120px" label-position="left"
            style="width: 300px">
            <el-form-item prop="content" label="问题（英文）:">
              <el-input style="width: 300px" :rows="4" type="textarea" v-model="form.content"
                placeholder="输入问题"></el-input>
            </el-form-item>
            <el-form-item prop="kf_type" label="问题类型：">
              <el-select style="width: 300px" v-model="form.kf_type" placeholder="请选择">
                <el-option v-for="(item, index) in prodType" :key="index" :label="item.name"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="kf_category" label="问题类型：">
              <el-select style="width: 300px" v-model="form.kf_category" placeholder="请选择">
                <el-option v-for="(item, index) in prodClass" :key="index" :label="item.name"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="form.kf_category == 2" prop="gc1_id" label="类目：">
              <el-select style="width: 300px" v-model="form.gc1_id" placeholder="请选择">
                <el-option v-for="(item, index) in category" :key="index" :label="item.name"
                  :value="item.category_id"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="onSubmit">提交</el-button>
              <el-button @click="handleBlcak">取消</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
  import { allCategory } from "@/api/common.js";
  import {
    getRobotKfList,
    saveRobotKfCommon,
    updateKfStatus,
  } from "@/api/admin.js";
  export default {
    data() {
      return {
        activeName: "management",
        tableData: {
          list: [],
        },

        prodType: [
          {
            value: 1,
            name: "售前",
          },
          {
            value: 2,
            name: "售中",
          },
          {
            value: 3,
            name: "售后",
          },
          {
            value: 6,
            name: "优惠券",
          },
          {
            value: 5,
            name: "商品推荐",
          },
          {
            value: 4,
            name: "出价",
          }
        ],
        prodClass: [
          {
            value: 1,
            name: "通用类目",
          },
          {
            value: 2,
            name: "类目",
          },
        ],
        page: 1,
        page_size: 10,
        form2: {
          content: "",
          kf_type: "",
          kf_category: "",
          gc1_id: "",
        },
        form: {
          id: "0",
          content: "",
          kf_type: "",
          kf_category: "",
          gc1_id: "",
        },
        rules: {
          content: [{ required: true, message: "必填", trigger: "blur" }],
          kf_type: [{ required: true, message: "必选", trigger: "change" }],
          kf_category: [{ required: true, message: "必选", trigger: "change" }],
        },

        btnType: true,
        title: "新增",
        category: [],
      };
    },
    created() {
      this.getList();
      this.allFootCategory();
    },
    methods: {
      getList() {
        //   let data = {};
        //   if (type == 2) {
        //     data = this.form2;
        //   }
        getRobotKfList({
          ...this.form2,
          page: this.page,
          page_size: this.page_size,
        }).then((res) => {
          if (res.code == 1) {
            this.tableData = res.data;
          }
        });
      },
      handleClick() {
        this.page = 1;
        this.getList();
      },
      onSubmit() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            saveRobotKfCommon({
              ...this.form,
            }).then((res) => {
              if (res.code == 1) {
                this.activeName = "management";
                this.$message.success(this.title + "成功");
                if (this.title == "新增") {
                  this.page = 1;
                }
                this.title = "新增";
                this.getList();
              }
            });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
      handleChange(value) {
        this.form.province_code = value[0].split(",")[0];
        this.form.province = value[0].split(",")[1];
        this.form.city_code = value[1].split(",")[0];
        this.form.city = value[1].split(",")[1];
        this.form.district_code = value[2].split(",")[0];
        this.form.district = value[2].split(",")[1];
      },
      handleBlcak() {
        this.$refs.form.resetFields();
        this.value = [];
        this.activeName = "management";
        this.title = "新增";
        this.acc = false;
      },
      handleEdit(val) {
        this.activeName = "add";
        this.title = "编辑";
        let data = JSON.stringify(val);
        this.form = JSON.parse(data);
      },
      handleQH(e) {
        if (e.name == "management") {
          this.title = "新增";
          this.$refs.form.resetFields();
        } else {
          this.form = {
            id: "0",
            content: "",
            kf_type: "",
            kf_category: "",
            gc1_id: "",
          };
          this.$refs.form.resetFields();
          this.acc = true;
        }
      },
      handleONandOFF(val, type) {
        updateKfStatus({
          id: val.id,
          status: type,
        }).then((res) => {
          if (res.code == 1) {
            this.$message.success("操作成功");
            this.getList();
          }
        });
      },
      handlePage(val) {
        this.page = val;
        this.getList();
      },
      allFootCategory() {
        allCategory().then((res) => {
          if (res.code == 1) {
            this.category = res.data;
          }
        });
      },
    },
  };
</script>
<style lang="less" scoped>
  .robot-address {
    padding: 20px;

    .cell {
      // padding: 10px;
      background: #fff;
    }
  }
</style>